@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Racesport";
  src: url("./assets/statistik/racefont/racesport.ttf") format("opentype");
}

@font-face {
  font-family: "Magr";
  src: url("./assets/statistik/magrfont/Magr/Magr-BoldItalic.otf") format("opentype");
}

.font-racesport {
  font-family: "Racesport", sans-serif;
}

.font-magr {
  font-family: "Magr", sans-serif;
}

.blur-shadow {
  outline: 2px solid transparent;
  border-radius: 2px; /* Adjust this value to match your content's border-radius */
  box-shadow: 0 0 2px 2px rgb(237, 225, 0); /* Adjust the blur radius and color as needed */
}
